import { Component, OnDestroy } from '@angular/core';
import { BreadcrumbService } from './breadcrumb.service';
import { Subscription } from 'rxjs';
import { MenuItem, ConfirmationService } from 'primeng/primeng';
import { Router } from '@angular/router';
import { ContextService } from './shared/context.service';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './app.breadcrumb.component.html',
    providers: [ConfirmationService]
})
export class AppBreadcrumbComponent implements OnDestroy {

    subscription: Subscription;

    items: MenuItem[];

    constructor(public breadcrumbService: BreadcrumbService, public router: Router, public contextService: ContextService,
        public confirmationService: ConfirmationService) {
        this.subscription = breadcrumbService.itemsHandler.subscribe(response => {
            this.items = response;
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
    calllogout(event) {
        this.confirmationService.confirm({
            message: 'Are you sure you want to logout ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: async () => {
               
                localStorage.clear();
                this.router.navigate(['/login']);
            },
            reject: () => {

                return;
            }
        });
    }
}

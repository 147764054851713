import { BillingFrequency } from '../dataModel/billing-frequency';
import { ModeOfPayment } from '../dataModel/mode-of-payment';
import { Tag } from '../dataModel/tag';
import { BundleSet } from './bundle-set';
import { SlabRate } from './slab-rate';
import { Unit } from '../dataModel/unit';
import { RecurringPeriod } from '../dataModel/recurringPeriod';
import { Message } from 'primeng/api';
import { WebResource } from '../dataModel/web-resource';
import { ContactTitle } from '../dataModel/contact-title';
import { NclOptionProduct } from '../dataModel/nonCommertialList.ts/nclOptionProduct';

export class ViewProduct {
    id: number;
    name: string;
    code: string;
    netAmount: number;
    recommendedAmount: number;
    productCost: number;
    contractPeriod: number;
    contractPeriodName: string;
    quantity: number;
    billingFrequency: BillingFrequency;
    paymentScheme: string;
    // gracePeriod: string;
    comment: string;
    needTraining: boolean;
    notice: string;
    currentOfficeId: number;
    tag: Tag[];
    bundle: BundleSet[];
    slabRate: SlabRate[];
    modeOfPayments: ModeOfPayment[];
    childCount: number;
    styleClass: string;
    unit: Unit;
    recurringPeriod: RecurringPeriod;
    minSelection: number;
    maxSelection: number;
    totalAmount: number;
    grandTotal: number;
    isBundle: boolean;
    isSet: boolean;
    isMandatory: boolean;
    isDetailsShow: boolean;
    isNeedBundleDetails: boolean;
    optionProductStatus: boolean;   // this status used in pendingTask and myRequest Pages
    setId: number;
    errorMsgs: Message[] = [];
    consumable: number;
    supportingDocument: WebResource;
    type: string;
    existingCount: number;
    requestCount: number;

    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    isContact: boolean = false;
    selectedSalutation: ContactTitle;

    nclOptionProduct: NclOptionProduct[];
    currentOfficeCode: string;
    remainingAmount: number;
    optionalProductMainCode: string;
}

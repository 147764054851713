import { ContactPerson } from './contact-person';
import { UserNodeUser } from './usrNodeUser';

export class User {

    id: number;
    loginId: string;
    password: string;
    status: string;
    createdDate: string;
    cntPerson: ContactPerson;
    usrNodeUser: UserNodeUser[];
    name: string;
}

import { Component } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { ContextService } from './shared/context.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { ContactTitle } from './dataModel/contact-title';
import { AdminService } from './admin/service/admin.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    providers: [ConfirmationService]
})
export class AppTopBarComponent {
    userName: string;
    cartCount: number = 0;
    subscription: Subscription;
    isSpinner: boolean;
    profilepic: string;
    selectedsalutation: ContactTitle;

    constructor(
        public app: AppMainComponent, public contextService: ContextService,
        public adminService: AdminService, public confirmationService: ConfirmationService,
        public router: Router) {
        this.subscription = this.contextService.getMessage().subscribe(img => {
            this.profilepic = img.text;
        });

        this.userName = localStorage.getItem('userName');
        this.profilepic = './../../../../assets/layout/images/avatar.png';
        this.getUserDetails();
    }
    calllogout(event) {
        this.confirmationService.confirm({
            message: 'Are you sure you want to logout ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: async () => {

                localStorage.clear();
                this.router.navigate(['/login']);
                //  this.router.navigate(['/login'], { queryParams: { session: 'logout' } });
            },
            reject: () => {

                return;
            }
        });
    }
    navigatePendingTask(event) {
        this.router.navigate(['/approval/pendingtasklist']);
    }
    getUserDetails() {
        this.adminService.getUserDetailsByID(localStorage.getItem('userId')).subscribe((data) => {
            this.adminService.userData = data;
            if (data !== undefined) {
                this.isSpinner = true;
                try {
                    this.selectedsalutation = data.data.cntPerson.cntTitle;
                    if (data.data.cntPerson.webResource !== undefined) {
                        if (data.data.cntPerson.webResource.multiMedia !== undefined) {
                            this.profilepic = data.data.cntPerson.webResource.multiMedia[0].url;
                        }
                    } else {
                        this.profilepic = './../../../../assets/layout/images/avatar.png';
                    }

                } catch (e) {
                    console.log('Error:', e.message);
                }
            }
            this.isSpinner = false;
        });
    }

}

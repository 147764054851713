import { Injectable } from "@angular/core";
import { TreeNode } from "primeng/api";
import { Product } from "../dataModel/product";
import { ViewProduct } from "../viewModel/view-product";
import { DuePeriod } from "../dataModel/due-period";
import { SlabRate } from "../viewModel/slab-rate";
import { BundleSet } from "../viewModel/bundle-set";
import { Bundle } from "../dataModel/bundle";
import { Option } from "../dataModel/option";
import { CurrencyPipe, DatePipe } from "@angular/common";
import { FundAllocationList } from "../dataModel/bc/fundAllocationList";
import { FundType } from "../dataModel/fundType";
import { BcAllocationList } from "../dataModel/bc/bcAllocationList";
import { HeaderType } from "../viewModel/headerType";
import { BcData } from "../dataModel/bc/bc-data";
import { Period } from "../dataModel/period";
import { WebResource } from "../dataModel/web-resource";
import { LazyLoading } from "../dataModel/lazyLoading";
import { Terminal } from "primeng/primeng";
import * as moment from "moment";

@Injectable()
export class HelperView {
  static checkStringEmptyOrNull(value) {
    if (value === null || value === undefined || value === "") {
      return false;
    } else {
      return true;
    }
  }
  static isValidMailFormat(email) {
    const emailRegex =
      /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
    if (
      email !== undefined &&
      email !== "" &&
      (email.length <= 5 || !emailRegex.test(email))
    ) {
      return false;
    }
    return true;
  }
  static isValidNumber(num) {
    const numRegex = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g; // eg: +(123) 456-7890  , (123) 456-7890 , +31636363634
    if (num !== undefined && num !== "" && !numRegex.test(num)) {
      return false;
    }
    return true;
  }
  static isValidAmountFormat(amount) {
    const regex = /^\d{1,10}(\.\d{1,2})?$/;
    if (regex.test(amount.toString())) {
      return false;
    }
    return true;
  }
  static checkObjectByID(approverEmployee: any[], id) {
    for (const approvar of approverEmployee) {
      if (approvar === id) {
        return true;
      }
    }
    return false;
  }
  static getUTCNow() {
    const now = new Date();
    const time = now.getTime();
    let offset = now.getTimezoneOffset();
    offset = offset * 60000;
    return time - offset;
  }
  static checkStatusNoNeed(statusDetails) {
    let need2Show: boolean = true;
    if (
      statusDetails === undefined ||
      statusDetails === "" ||
      statusDetails === "List is empty." ||
      statusDetails === "List is Empty" ||
      statusDetails === "List is Empty." ||
      statusDetails === "List is empty" ||
      statusDetails ===
        "Internal Server Error - Contact your software vendor." ||
      statusDetails === "Could not find records"
    ) {
      need2Show = false;
    }

    return need2Show;
  }
  static isValidFloatFormat(num) {
    const testObj = num;
    const curRegexp = /^(\d{0,7})(\.\d{0,5})?$/i;
    if (!curRegexp.test(testObj)) {
      return false;
    }
    return true;
  }
  static filterSearch(event, originalList) {
    this.sortList(originalList);
    const filterList: any[] = [];
    for (const submarket of originalList) {
      if (
        submarket.name.toLowerCase().indexOf(event.query.toLowerCase()) >= 0
      ) {
        filterList.push(submarket);
      }
    }
    return filterList;
  }
  static sortList(list) {
    try {
      list.sort(function (a, b) {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (nameA < nameB) {
          // sort string ascending
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0; // default return value (no sorting)
      });
    } catch (e) {
      console.log("Error:", e.message);
    }
  }

  // -------------

  static generateTreeNodeProduct(
    dataRow: Product,
    contractPeriod,
    curOfficeCode,
    parentInfo,
    productType
  ) {
    try {
      let rootNode: TreeNode;
      let parent: TreeNode;

      const productData: ViewProduct = new ViewProduct();

      productData.id = dataRow.id;
      productData.name = dataRow.name;
      productData.code = dataRow.code;
      productData.currentOfficeCode = curOfficeCode;
      productData.type = productType;

      if (productType === undefined || productType === "product") {
        productData.contractPeriod = contractPeriod;
        productData.netAmount = dataRow.pricing.rate[0].price;
        productData.recommendedAmount =
          dataRow.pricing.rate[0].recommendedPrice;
        productData.productCost = dataRow.pricing.rate[0].recommendedPrice;
        productData.recurringPeriod = dataRow.pricing.rate[0].recurringPeriod;

        if (productData.recurringPeriod.duePeriod === undefined) {
          const duePeriodObj: DuePeriod = new DuePeriod();
          const periodObj: Period = new Period();
          periodObj.id = 3;
          periodObj.name = "Day";
          duePeriodObj.period = periodObj;
          duePeriodObj.frequency = 0;
          productData.recurringPeriod.duePeriod = duePeriodObj;
        }

        productData.unit = dataRow.pricing.rate[0].unit;
        productData.isDetailsShow = false;
        productData.quantity = 0;
        productData.grandTotal = 0;
        productData.totalAmount = 0;
        productData.paymentScheme = "advanced";
        productData.needTraining = false;
        productData.notice = null;
        productData.modeOfPayments = dataRow.paymentMode;
        productData.optionProductStatus = true;

        if (
          dataRow.selectionCriteria !== undefined &&
          dataRow.selectionCriteria.length
        ) {
          productData.minSelection = dataRow.selectionCriteria[0].min;
          productData.maxSelection = dataRow.selectionCriteria[0].max;
        }

        if (dataRow.pricing.rate.length !== 1) {
          productData.slabRate = [];
          for (const rate of dataRow.pricing.rate) {
            const dataRate: SlabRate = new SlabRate();
            dataRate.id = rate.id;
            dataRate.netAmount = rate.price;
            dataRate.recommendedAmount = rate.recommendedPrice;
            if (rate.slab !== undefined) {
              dataRate.quantityFrom = rate.slab.quantityFrom;
              dataRate.quantityTo = rate.slab.quantityTo;
            }
            dataRate.recurringPeriod = rate.recurringPeriod;
            if (rate.unit !== undefined) {
              dataRate.unit = rate.unit;
            }
            productData.slabRate.push(dataRate);
          }
        }
      }
      rootNode = {
        data: productData,
        children: [],
        expanded: false,
        parent: parentInfo !== null ? parentInfo : null,
      };
      parent = rootNode;

      if (dataRow.bundle !== undefined) {
        productData.isBundle = true;
        productData.isSet = false;
        productData.childCount = dataRow.bundle.set.length;
        productData.isNeedBundleDetails = false;
        rootNode = this.constructProductBundleSet(
          rootNode,
          dataRow.bundle,
          parent,
          contractPeriod,
          curOfficeCode,
          productType
        );
      } else {
        productData.isBundle = false;
        productData.isSet = false;
        productData.styleClass = "noelement";
      }

      return rootNode;
    } catch (e) {
      console.log("Error:", e.message);
    }
  }
  /**
   * @function generateTreeNodeBill
   * @param dataRow
   * @param curOfficeCode
   * @param parentInfo
   * @param productType
   * @description treenode generation for Products for Bill Update
   * @returns
   */
  static generateTreeNodeBill(
    dataRow: Product,
    curOfficeCode,
    parentInfo,
    productType
  ) {
    try {
      let rootNode: TreeNode;
      let parent: TreeNode;

      const productData: ViewProduct = new ViewProduct();

      productData.id = dataRow.id;
      productData.name = dataRow.name;
      productData.code = dataRow.code;
      productData.currentOfficeCode = curOfficeCode;
      productData.type = productType;

      if (productType === undefined || productType === "product") {
        productData.netAmount = dataRow.pricing.rate[0].price;
        productData.recommendedAmount =
          dataRow.pricing.rate[0].recommendedPrice;
        productData.productCost = dataRow.pricing.rate[0].recommendedPrice;
        productData.recurringPeriod = dataRow.pricing.rate[0].recurringPeriod;
        if (productData.recurringPeriod.duePeriod === undefined) {
          const duePeriodObj: DuePeriod = new DuePeriod();
          const periodObj: Period = new Period();
          periodObj.id = 3;
          periodObj.name = "Day";
          duePeriodObj.period = periodObj;
          duePeriodObj.frequency = 0;
          productData.recurringPeriod.duePeriod = duePeriodObj;
        }

        productData.unit = dataRow.pricing.rate[0].unit;
        productData.isDetailsShow = false;
        productData.quantity = 0;
        productData.grandTotal = 0;
        productData.totalAmount = 0;
        productData.paymentScheme = "advanced";
        productData.needTraining = false;
        productData.notice = null;
        productData.modeOfPayments = dataRow.paymentMode;
        productData.optionProductStatus = true;

        if (
          dataRow.selectionCriteria !== undefined &&
          dataRow.selectionCriteria.length
        ) {
          productData.minSelection = dataRow.selectionCriteria[0].min;
          productData.maxSelection = dataRow.selectionCriteria[0].max;
        }

        if (dataRow.pricing.rate.length !== 1) {
          productData.slabRate = [];
          for (const rate of dataRow.pricing.rate) {
            const dataRate: SlabRate = new SlabRate();
            dataRate.id = rate.id;
            dataRate.netAmount = rate.price;
            dataRate.recommendedAmount = rate.recommendedPrice;
            if (rate.slab !== undefined) {
              dataRate.quantityFrom = rate.slab.quantityFrom;
              dataRate.quantityTo = rate.slab.quantityTo;
            }
            dataRate.recurringPeriod = rate.recurringPeriod;
            if (rate.unit !== undefined) {
              dataRate.unit = rate.unit;
            }
            productData.slabRate.push(dataRate);
          }
        }
      }

      rootNode = {
        data: productData,
        children: [],
        expanded: false,
        parent: parentInfo !== null ? parentInfo : null,
      };
      parent = rootNode;

      if (dataRow.bundle !== undefined) {
        productData.isBundle = true;
        productData.isSet = false;
        productData.childCount = dataRow.bundle.set.length;
        productData.isNeedBundleDetails = false;
        rootNode = this.constructBillBundleSet(
          rootNode,
          dataRow.bundle,
          parent,
          curOfficeCode,
          productType
        );
      } else {
        productData.isBundle = false;
        productData.isSet = false;
        productData.styleClass = "noelement";
      }

      return rootNode;
    } catch (e) {
      console.log("Error:", e.message);
    }
  }
  /**
   * @function  constructBillBundleSet
   * @param rootNode
   * @param bundleProduct
   * @param parent
   * @param curOfficeCode
   * @param productType
   * @description to construct bundle of product for bill update
   * @returns
   */

  static constructBillBundleSet(
    rootNode: TreeNode,
    bundleProduct: Bundle,
    parent,
    curOfficeCode,
    productType
  ) {
    try {
      if (bundleProduct !== undefined) {
        bundleProduct.set.forEach((bundleObj) => {
          const bundleRow: BundleSet = new BundleSet();
          bundleRow.id = bundleObj.id;
          bundleRow.name = bundleObj.name;
          bundleRow.totalMin = bundleObj.totalMin;
          bundleRow.totalMax = bundleObj.totalMax;
          bundleRow.isDetailsShow = false;
          bundleRow.isSet = true;
          bundleRow.optionProductStatus = false;
          const treeNodeRow: TreeNode = {
            data: bundleRow,
            children: [],
            expanded: false,
            parent: parent,
          };
          if (rootNode !== undefined) {
            rootNode.children.push(treeNodeRow);
          }
          if (bundleObj.option !== undefined) {
            bundleRow.isBundle = false;
            bundleRow.isSet = true;
            bundleRow.childCount = bundleObj.option.length;
            let isMandatory: boolean;
            if (
              bundleObj.totalMin === bundleObj.totalMax &&
              bundleObj.totalMax === bundleObj.option.length
            ) {
              isMandatory = true;
            } else {
              isMandatory = false;
            }

            parent = treeNodeRow;
            this.constructBillSetOptions(
              treeNodeRow,
              bundleObj.option,
              parent,
              curOfficeCode,
              isMandatory,
              productType
            );
          }
        });
      }
      return rootNode;
    } catch (e) {
      console.log("Error:", e.message);
    }
  }
  /**
   * @function constructBillSetOptions
   * @param rootNode
   * @param optionList
   * @param parent
   * @param curOfficeCode
   * @param isMandatory
   * @param productType
   * @description To construct child set product for bill update
   * @returns
   */
  static constructBillSetOptions(
    rootNode: TreeNode,
    optionList: Option[],
    parent,
    curOfficeCode,
    isMandatory,
    productType
  ) {
    try {
      if (optionList !== undefined) {
        optionList.forEach((optionObj) => {
          const productData: ViewProduct = new ViewProduct();

          productData.id = optionObj.product.id;
          productData.setId = optionObj.setId;
          productData.name = optionObj.product.name;
          productData.code = optionObj.code;
          productData.optionalProductMainCode = optionObj.product.code;
          if (optionObj.product.tag !== undefined) {
            productData.tag = optionObj.product.tag;
          }
          productData.currentOfficeCode = curOfficeCode;

          if (productType === undefined || productType === "product") {
            productData.netAmount = optionObj.productPricing.rate[0].price;
            productData.recommendedAmount =
              optionObj.productPricing.rate[0].recommendedPrice;
            productData.productCost =
              optionObj.productPricing.rate[0].recommendedPrice;
            productData.recurringPeriod =
              optionObj.productPricing.rate[0].recurringPeriod;
            productData.unit = optionObj.productPricing.rate[0].unit;

            if (productData.recurringPeriod.duePeriod === undefined) {
              const duePeriodObj: DuePeriod = new DuePeriod();
              const periodObj: Period = new Period();
              periodObj.id = 3;
              periodObj.name = "Day";
              duePeriodObj.period = periodObj;
              duePeriodObj.frequency = 0;
              productData.recurringPeriod.duePeriod = duePeriodObj;
            }

            productData.quantity = 0;
            productData.grandTotal = 0;
            productData.paymentScheme = "advanced";
            productData.needTraining = false;
            productData.notice = null;
            productData.modeOfPayments = optionObj.paymentMode;

            productData.minSelection = optionObj.minSelection;
            productData.maxSelection = optionObj.maxSelection;

            if (optionObj.productPricing.rate.length !== 1) {
              productData.slabRate = [];
              for (const rate of optionObj.productPricing.rate) {
                const dataRate: SlabRate = new SlabRate();
                dataRate.id = rate.id;
                dataRate.netAmount = rate.price;
                dataRate.recommendedAmount = rate.recommendedPrice;
                if (rate.slab !== undefined) {
                  dataRate.quantityFrom = rate.slab.quantityFrom;
                  dataRate.quantityTo = rate.slab.quantityTo;
                }
                dataRate.recurringPeriod = rate.recurringPeriod;
                if (rate.unit !== undefined) {
                  dataRate.unit = rate.unit;
                }

                productData.slabRate.push(dataRate);
              }
            }
          } else {
          }

          productData.isDetailsShow = false;
          productData.isMandatory = isMandatory;
          productData.optionProductStatus = false;
          const treeNodeRow: TreeNode = {
            data: productData,
            children: [],
            expanded: false,
            parent: parent,
          };

          if (rootNode !== undefined) {
            rootNode.children.push(treeNodeRow);
          }
          if (optionObj.product.bundle !== undefined) {
            parent = treeNodeRow;
            productData.isBundle = true;
            productData.isSet = false;

            const bundleProductData: ViewProduct = new ViewProduct();

            let dummyData: TreeNode = {
              data: bundleProductData,
              children: [],
              expanded: false,
              parent: treeNodeRow,
            };
            treeNodeRow.children.push(dummyData);
            productData.isNeedBundleDetails = true;
          }
        });
      }
      return rootNode;
    } catch (e) {
      console.log("Error:", e.message);
    }
  }

  static constructProductBundleSet(
    rootNode: TreeNode,
    bundleProduct: Bundle,
    parent,
    contractPeriod,
    curOfficeCode,
    productType
  ) {
    try {
      if (bundleProduct !== undefined) {
        bundleProduct.set.forEach((bundleObj) => {
          const bundleRow: BundleSet = new BundleSet();
          bundleRow.id = bundleObj.id;
          bundleRow.name = bundleObj.name;
          bundleRow.totalMin = bundleObj.totalMin;
          bundleRow.totalMax = bundleObj.totalMax;
          bundleRow.isDetailsShow = false;
          bundleRow.isSet = true;
          bundleRow.optionProductStatus = false;
          const treeNodeRow: TreeNode = {
            data: bundleRow,
            children: [],
            expanded: false,
            parent: parent,
          };

          if (rootNode !== undefined) {
            rootNode.children.push(treeNodeRow);
          }
          if (bundleObj.option !== undefined) {
            bundleRow.isBundle = false;
            bundleRow.isSet = true;
            bundleRow.childCount = bundleObj.option.length;
            let isMandatory: boolean;
            if (
              bundleObj.totalMin === bundleObj.totalMax &&
              bundleObj.totalMax === bundleObj.option.length
            ) {
              isMandatory = true;
            } else {
              isMandatory = false;
            }

            parent = treeNodeRow;
            this.constructSetOptions(
              treeNodeRow,
              bundleObj.option,
              parent,
              contractPeriod,
              curOfficeCode,
              isMandatory,
              productType
            );
          }
        });
      }
      return rootNode;
    } catch (e) {
      console.log("Error:", e.message);
    }
  }
  static constructSetOptions(
    rootNode: TreeNode,
    optionList: Option[],
    parent,
    contractPeriod,
    curOfficeCode,
    isMandatory,
    productType
  ) {
    try {
      if (optionList !== undefined) {
        optionList.forEach((optionObj) => {
          const productData: ViewProduct = new ViewProduct();

          productData.id = optionObj.product.id;
          productData.setId = optionObj.setId;
          productData.name = optionObj.product.name;
          productData.code = optionObj.code;
          productData.optionalProductMainCode = optionObj.product.code;
          if (optionObj.product.tag !== undefined) {
            productData.tag = optionObj.product.tag;
          }
          productData.currentOfficeCode = curOfficeCode;

          if (productType === undefined || productType === "product") {
            productData.contractPeriod = contractPeriod;
            productData.netAmount = optionObj.productPricing.rate[0].price;
            productData.recommendedAmount =
              optionObj.productPricing.rate[0].recommendedPrice;
            productData.productCost =
              optionObj.productPricing.rate[0].recommendedPrice;
            productData.recurringPeriod =
              optionObj.productPricing.rate[0].recurringPeriod;
            productData.unit = optionObj.productPricing.rate[0].unit;

            if (productData.recurringPeriod.duePeriod === undefined) {
              const duePeriodObj: DuePeriod = new DuePeriod();
              const periodObj: Period = new Period();
              periodObj.id = 3;
              periodObj.name = "Day";
              duePeriodObj.period = periodObj;
              duePeriodObj.frequency = 0;
              productData.recurringPeriod.duePeriod = duePeriodObj;
            }

            productData.quantity = 0;
            productData.grandTotal = 0;
            productData.paymentScheme = "advanced";
            productData.needTraining = false;
            productData.notice = null;
            productData.modeOfPayments = optionObj.paymentMode;

            productData.minSelection = optionObj.minSelection;
            productData.maxSelection = optionObj.maxSelection;

            if (optionObj.productPricing.rate.length !== 1) {
              productData.slabRate = [];
              for (const rate of optionObj.productPricing.rate) {
                const dataRate: SlabRate = new SlabRate();
                dataRate.id = rate.id;
                dataRate.netAmount = rate.price;
                dataRate.recommendedAmount = rate.recommendedPrice;
                if (rate.slab !== undefined) {
                  dataRate.quantityFrom = rate.slab.quantityFrom;
                  dataRate.quantityTo = rate.slab.quantityTo;
                }
                dataRate.recurringPeriod = rate.recurringPeriod;
                if (rate.unit !== undefined) {
                  dataRate.unit = rate.unit;
                }

                productData.slabRate.push(dataRate);
              }
            }
          } else {
          }

          productData.isDetailsShow = false;
          productData.isMandatory = isMandatory;
          productData.optionProductStatus = false;
          const treeNodeRow: TreeNode = {
            data: productData,
            children: [],
            expanded: false,
            parent: parent,
          };

          if (rootNode !== undefined) {
            rootNode.children.push(treeNodeRow);
          }
          if (optionObj.product.bundle !== undefined) {
            parent = treeNodeRow;
            productData.isBundle = true;
            productData.isSet = false;

            const bundleProductData: ViewProduct = new ViewProduct();

            let dummyData: TreeNode = {
              data: bundleProductData,
              children: [],
              expanded: false,
              parent: treeNodeRow,
            };
            treeNodeRow.children.push(dummyData);
            productData.isNeedBundleDetails = true;
          }
        });
      }
      return rootNode;
    } catch (e) {
      console.log("Error:", e.message);
    }
  }
  static isAutoComplteValid(value) {
    try {
      if (value.id === undefined) {
        return false;
      }
      return true;
    } catch (e) {
      console.log("Error:", e.message);
    }
  }
  static calculateGrandTotal(productReq) {
    try {
      let totalAmount = 0;
      if (productReq.paymentTerms.amount !== undefined) {
        totalAmount = productReq.paymentTerms.amount;
        if (
          productReq.optionProductItemRequestList !== undefined &&
          productReq.optionProductItemRequestList.length > 0
        ) {
          productReq.optionProductItemRequestList.forEach((optionRow) => {
            if (optionRow.paymentTerms.amount !== undefined) {
              totalAmount += optionRow.paymentTerms.amount;
            }
          });
        }
      }
      return totalAmount;
    } catch (e) {
      console.log("Error:", e.message);
    }
  }
  static setStatusStyle(statusId: number, status: string, type): string {
    let styleClassName;

    if (type === "request") {
      if (statusId === 1) {
        styleClassName = "requestedStatus";
      } else if (statusId === 2) {
        styleClassName = "processingStatus";
      } else if (statusId === 3) {
        styleClassName = "completedStatus";
      } else if (statusId === 4) {
        styleClassName = "rejectedStatus";
      } else if (statusId === 5) {
        styleClassName = "cancelStatus";
      } else if (statusId === 6) {
        styleClassName = "onholdStatus";
      }
      return styleClassName;
    }
    if (type === "item") {
      if (statusId === 1) {
        styleClassName = "requestedStatus";
      } else if (statusId === 2) {
        styleClassName = "approvedStatus";
      } else if (statusId === 3) {
        styleClassName = "reviewStatus";
      } else if (statusId === 4) {
        styleClassName = "rejectedStatus";
      } else if (statusId === 5) {
        styleClassName = "implementedStatus";
      } else if (statusId === 6) {
        styleClassName = "disallowStatus";
      } else if (statusId === 7) {
        styleClassName = "pendingStatus";
      } else if (statusId === 8) {
        styleClassName = "commissionedStatus";
      } else if (statusId === 9) {
        styleClassName = "scheduledStatus";
      } else if (statusId === 10) {
        styleClassName = "noworkflowStatus";
      } else if (statusId === 11) {
        styleClassName = "queryStatus";
      }
      return styleClassName;
    }
    if (type === "pending") {
      if (status === "commissioned") {
        styleClassName = "commissionedStatus";
      } else if (status === "approve") {
        styleClassName = "approvedStatus";
      } else if (status === "review") {
        styleClassName = "reviewStatus";
      } else if (status === "Implementation") {
        styleClassName = "implementedStatus";
      }
      return styleClassName;
    }

    if (type === "status") {
      if (statusId === 2) {
        styleClassName = "pendingStatus";
      } else if (statusId === 1) {
        styleClassName = "activeStatus";
      } else if (statusId === 3) {
        styleClassName = "inactiveStatus";
      }
      return styleClassName;
    }
    if (type === "noId") {
      if (status === "Requested") {
        styleClassName = "requestedStatus";
      } else if (status === "Approve") {
        styleClassName = "approvedStatus";
      } else if (status === "Review") {
        styleClassName = "reviewStatus";
      } else if (status === "Rejected") {
        styleClassName = "rejectedStatus";
      } else if (status === "Implemented") {
        styleClassName = "implementedStatus";
      } else if (status === "Disallow") {
        styleClassName = "disallowStatus";
      } else if (status === "Pending") {
        styleClassName = "pendingStatus";
      } else if (status === "Commissioned") {
        styleClassName = "commissionedStatus";
      } else if (status === "Scheduled") {
        styleClassName = "scheduledStatus";
      } else if (status === "No WorkFlow") {
        styleClassName = "noworkflowStatus";
      } else if (status === "Query") {
        styleClassName = "queryStatus";
      }
      return styleClassName;
    }
  }
  static removeCircularJson(item) {
    var cache = [];
    var itemString = JSON.stringify(item, function (key, value) {
      if (typeof value === "object" && value !== null) {
        if (cache.indexOf(value) !== -1) {
          // Duplicate reference found, discard key
          return;
        }
        // Store value in our collection
        cache.push(value);
      }
      return value;
    });
    cache = null;
    return itemString;
  }
  static isEnabledFunctionality(value: number): boolean {
    try {
      const roleList = JSON.parse(
        localStorage.getItem("userPageFunctionalities")
      );
      if (roleList !== null && roleList.length > 0) {
        for (const role of roleList) {
          if (role.funNodeFunctionalityRoleUserList !== undefined) {
            for (const funRow of role.funNodeFunctionalityRoleUserList) {
              if (value === funRow.functionalityId) {
                return true;
              }
            }
          }
        }
      }
      return false;
    } catch (e) {
      console.log("Error: ", e.message);
    }
  }
  static isValidName(name) {
    const re = /^[A-Za-z. ]+$/; // eg:only allowed alphabets,space,.
    if (name !== undefined && !re.test(name)) {
      return false;
    }
    return true;
  }
  static isValidCharactersInSignIn(signIn): boolean {
    const validCharactersInSignIn = /^[A-Za-z0-9]+$/; // eg:only allowed alphanumeric characters.
    if (signIn !== undefined && !validCharactersInSignIn.test(signIn)) {
      return false;
    }
    return true;
  }
  static isValidPhoneNumber(number): boolean {
    const validPhoneNumber = /^[+]?[0-9]+$/; // eg: +31636363634,  31636363634
    if (number !== undefined && !validPhoneNumber.test(number)) {
      return false;
    }
    return true;
  }
  static isValidCountryCode(number): boolean {
    const validCountryCode = /^\d{1,3}$/; // eg: 971,  968
    if (number !== undefined && !validCountryCode.test(number)) {
      return false;
    }
    return true;
  }
  static calculateMonthDifference(startDate, uploadedDate): string {
    let monthDifference;
    var stDate = moment(startDate, "MMM YYYY");
    var upDate = moment(uploadedDate, "DD/MM/YYYY");
    monthDifference = stDate.diff(upDate, "months").toString();
    return monthDifference;
  }
  static fundTotalAmount(rowObj): number {
    let fundAndTotalAmount = 0;
    fundAndTotalAmount =
      (rowObj.year2 !== undefined ? parseFloat(rowObj.year2) : 0) +
      (rowObj.year3 !== undefined ? parseFloat(rowObj.year3) : 0) +
      (rowObj.year4 !== undefined ? parseFloat(rowObj.year4) : 0) +
      (rowObj.year5 !== undefined ? parseFloat(rowObj.year5) : 0) +
      (rowObj.year6 !== undefined ? parseFloat(rowObj.year6) : 0) +
      (rowObj.year7 !== undefined ? parseFloat(rowObj.year7) : 0);
    // console.log('Fund And TotalAmount :', fundAndTotalAmount);
    return fundAndTotalAmount;
  }
  static processBcData(bcData: BcData) {
    const processedBc = {
      bcData: null,
      bcProductAndServiceHeaders: [],
      yearList: [],
      bcHardwareFundTotalAmount: 0,
      bcDevelopmentFundTotalAmount: 0,
      bcGlobalFundTotalAmount: 0,
    };

    const bcProductAndServiceHeaders: HeaderType[] = [];
    const yearList: HeaderType[] = [];

    if (
      bcData.equipmentsAndCommunicationLines.EquipmentsCommunicationSummary !==
      undefined
    ) {
      const equRow =
        bcData.equipmentsAndCommunicationLines
          .EquipmentsCommunicationSummary[0];

      bcProductAndServiceHeaders.push({
        header: "Equipment Name",
        field: "equipmentName",
        headerA: "Attributes",
        fieldA: "attributes",
        headerP: "Product/Service",
        fieldP: "productInstanceName",
        width: "35%",
      });

      // bcProductAndServiceHeaders.push({
      //   header: 'Code', field: 'productInstanceCode',
      //   headerA: '', fieldA: '',
      //   headerP: '', fieldP: '', width: '8%'
      // });

      if (equRow.year1 !== undefined) {
        this.addHeaderRow(
          bcProductAndServiceHeaders,
          yearList,
          equRow.year1,
          "year1"
        );
        // const row = { header: equRow.year1, field: 'year1' };
        // bcProductAndServiceHeaders.push(row);
        // yearList.push(row);
      }
      if (equRow.year2 !== undefined) {
        this.addHeaderRow(
          bcProductAndServiceHeaders,
          yearList,
          equRow.year2,
          "year2"
        );
      }
      if (equRow.year3 !== undefined) {
        this.addHeaderRow(
          bcProductAndServiceHeaders,
          yearList,
          equRow.year3,
          "year3"
        );
      }
      if (equRow.year4 !== undefined) {
        this.addHeaderRow(
          bcProductAndServiceHeaders,
          yearList,
          equRow.year4,
          "year4"
        );
      }
      if (equRow.year5 !== undefined) {
        this.addHeaderRow(
          bcProductAndServiceHeaders,
          yearList,
          equRow.year5,
          "year5"
        );
      }
      if (equRow.year6 !== undefined) {
        this.addHeaderRow(
          bcProductAndServiceHeaders,
          yearList,
          equRow.year6,
          "year6"
        );
      }
      if (equRow.year7 !== undefined) {
        this.addHeaderRow(
          bcProductAndServiceHeaders,
          yearList,
          equRow.year7,
          "year7"
        );
      }
    }

    if (bcData.hardwareFund !== undefined) {
      processedBc.bcHardwareFundTotalAmount = this.fundTotalAmount(
        bcData.hardwareFund
      );
    }
    if (bcData.developmentFund !== undefined) {
      processedBc.bcDevelopmentFundTotalAmount = this.fundTotalAmount(
        bcData.developmentFund
      );
    }
    if (bcData.otherFund !== undefined) {
      processedBc.bcGlobalFundTotalAmount = this.fundTotalAmount(
        bcData.otherFund
      );
    }

    bcData.equipmentsAndCommunicationLines.EquipmentsCommunicationSummary.splice(
      0,
      1
    );
    bcData.equipmentsAndCommunicationLines.EquipmentsCommunicationSummary =
      this.fillEquipmentProductServiceData(
        bcData.equipmentsAndCommunicationLines.EquipmentsCommunicationSummary,
        "Equipment"
      );
    bcData.productAndServices.ProductServices =
      this.fillEquipmentProductServiceData(
        bcData.productAndServices.ProductServices,
        "ProductService"
      );

    processedBc.bcData = bcData;
    processedBc.bcProductAndServiceHeaders = bcProductAndServiceHeaders;
    processedBc.yearList = yearList;

    return processedBc;
  }
  static fillEquipmentProductServiceData(list, type) {
    if (list !== undefined && list.length > 0) {
      const equList: any[] = [];
      for (let i = 0; i < list.length; i++) {
        const row = list[i];

        if (type === "Equipment") {
          if (i !== 0) {
            if (
              row.equipmentName !== undefined &&
              row.equipmentName !== "HARDWARE FUND" &&
              (row.year1 !== undefined ||
                row.year2 !== undefined ||
                row.year3 !== undefined ||
                row.year4 !== undefined ||
                row.year5 !== undefined ||
                row.year6 !== undefined ||
                row.year7 !== undefined)
            ) {
              equList.push(row);
            }
          }
        } else {
          if (
            row.productInstanceCode !== undefined &&
            row.productInstanceName !== undefined &&
            (row.year1 !== undefined ||
              row.year2 !== undefined ||
              row.year3 !== undefined ||
              row.year4 !== undefined ||
              row.year5 !== undefined ||
              row.year6 !== undefined ||
              row.year7 !== undefined)
          ) {
            equList.push(row);
          }
        }
      }
      return equList;
    }
  }
  static addHeaderRow(bcProductAndServiceHeaders, yearList, header, field) {
    const headerRow: HeaderType = new HeaderType();
    headerRow.header = header;
    headerRow.field = field;
    bcProductAndServiceHeaders.push(headerRow);
    yearList.push(headerRow);
  }
  static getApprovalFlow(approvalFlow): string {
    let temp: any[] = [];
    approvalFlow.forEach((element) => {
      if (
        element.approvalLevels !== undefined &&
        element.approvalLevels.substring(0, 1) === "L"
      ) {
        temp.push({ name: element.approvalLevels });
      }
    });
    if (temp.length > 0) {
      HelperView.sortList(temp);
      const approvalFlowRow = temp[temp.length - 1];
      // console.log('level', approvalFlowRow);
      return approvalFlowRow.name;
    } else {
      return "WRONG";
    }
  }
  static getAcoApproval(acoApproval): string {
    const acoApprovalRow = acoApproval[acoApproval.length - 1].approvalLevels;
    if (acoApprovalRow.includes("WEMEA") && acoApprovalRow.includes("OK")) {
      return "WRONG";
    } else if (acoApprovalRow.includes("WEMEA")) {
      return acoApprovalRow;
    } else if (acoApprovalRow.includes("OK")) {
      return acoApprovalRow;
    } else {
      return "WRONG";
    }
  }
  static fileUploadUrlPath(accountId, type) {
    const registerDate = new Date();
    const locale = "en-us";
    const uploadPattern = "d MMM y hh:mm:ss";
    const uploadDatePipe = new DatePipe(locale);
    const newregisterDate = uploadDatePipe.transform(
      registerDate,
      uploadPattern
    );
    let uploadPath;
    if (type === "Product") {
      uploadPath = encodeURIComponent("Products/" + newregisterDate);
    } else if (type === "Profile") {
      uploadPath = encodeURIComponent("UserDetails/" + newregisterDate);
    } else {
      uploadPath = encodeURIComponent(
        "Accounts/" + accountId + "/" + type + "/" + newregisterDate
      );
    }

    return uploadPath;
  }
  static bcYearAllocation(rowObj, allocationList, yearList) {
    if (rowObj.year1 !== undefined) {
      const allocationRow: BcAllocationList = {
        year: this.getYearValue("year1", yearList),
        quota: +rowObj.year1,
      };
      allocationList.push(allocationRow);
    }
    if (rowObj.year2 !== undefined) {
      const allocationRow: BcAllocationList = {
        year: this.getYearValue("year2", yearList),
        quota: +rowObj.year2,
      };
      allocationList.push(allocationRow);
    }
    if (rowObj.year3 !== undefined) {
      const allocationRow: BcAllocationList = {
        year: this.getYearValue("year3", yearList),
        quota: +rowObj.year3,
      };
      allocationList.push(allocationRow);
    }
    if (rowObj.year4 !== undefined) {
      const allocationRow: BcAllocationList = {
        year: this.getYearValue("year4", yearList),
        quota: +rowObj.year4,
      };
      allocationList.push(allocationRow);
    }
    if (rowObj.year5 !== undefined) {
      const allocationRow: BcAllocationList = {
        year: this.getYearValue("year5", yearList),
        quota: +rowObj.year5,
      };
      allocationList.push(allocationRow);
    }
    if (rowObj.year6 !== undefined) {
      const allocationRow: BcAllocationList = {
        year: this.getYearValue("year6", yearList),
        quota: +rowObj.year6,
      };
      allocationList.push(allocationRow);
    }
    if (rowObj.year7 !== undefined) {
      const allocationRow: BcAllocationList = {
        year: this.getYearValue("year7", yearList),
        quota: +rowObj.year7,
      };
      allocationList.push(allocationRow);
    }
  }
  static getYearValue(yearField, yearList) {
    try {
      if (yearList.length > 0) {
        for (const row of yearList) {
          if (row.field === yearField) {
            return row.header;
          }
        }
      }
    } catch (e) {
      console.log("Error:", e.message);
    }
  }
  static bcFundAllocation(rowObj, allocationList, type, yearList) {
    try {
      if (rowObj.year1 !== undefined) {
        this.bcFundAllocationPerYear(
          "year1",
          +rowObj.year1,
          allocationList,
          type,
          yearList
        );
      }
      if (rowObj.year2 !== undefined) {
        this.bcFundAllocationPerYear(
          "year2",
          +rowObj.year2,
          allocationList,
          type,
          yearList
        );
      }
      if (rowObj.year3 !== undefined) {
        this.bcFundAllocationPerYear(
          "year3",
          +rowObj.year3,
          allocationList,
          type,
          yearList
        );
      }
      if (rowObj.year4 !== undefined) {
        this.bcFundAllocationPerYear(
          "year4",
          +rowObj.year4,
          allocationList,
          type,
          yearList
        );
      }
      if (rowObj.year5 !== undefined) {
        this.bcFundAllocationPerYear(
          "year5",
          +rowObj.year5,
          allocationList,
          type,
          yearList
        );
      }
      if (rowObj.year6 !== undefined) {
        this.bcFundAllocationPerYear(
          "year6",
          +rowObj.year6,
          allocationList,
          type,
          yearList
        );
      }
      if (rowObj.year7 !== undefined) {
        this.bcFundAllocationPerYear(
          "year7",
          +rowObj.year7,
          allocationList,
          type,
          yearList
        );
      }
    } catch (e) {
      console.log("Error:", e.message);
    }
  }
  static bcFundAllocationPerYear(year, value, list, type, yearList) {
    try {
      const allocationRow: FundAllocationList = new FundAllocationList();
      allocationRow.year = this.getYearValue(year, yearList);
      allocationRow.amount = value;
      const fundType: FundType = new FundType();
      // type === 'Hardware' ? 3 : 3;  // 1- Global fund , 2- local fund/Developmentfund , 3- hardware fund
      if (type === "Hardware") {
        fundType.id = 3;
      } else if (type === "Development") {
        fundType.id = 2;
      } else if (type === "Global") {
        fundType.id = 1;
      }
      allocationRow.fundType = fundType;
      list.push(allocationRow);
    } catch (e) {
      console.log("Error:", e.message);
    }
  }

  // lazy...

  static lazyLoading(from, size, list, type, sortAndFilterData: LazyLoading) {
    sortAndFilterData.from = from;
    sortAndFilterData.size = size;
    if (type === "Sort") {
      sortAndFilterData.sortList = list;
    }
    if (type === "Filter") {
      sortAndFilterData.filterList = list;
    }
    return sortAndFilterData;
  }

  static exportExcel(exportList, reportCaption) {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(exportList);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      this.saveAsExcelFile(excelBuffer, reportCaption);
    });
  }

  static saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      FileSaver.saveAs(
        data,
        fileName +
          "_" +
          this.convertDateString(new Date(), "dd-MMM-yyyy") +
          EXCEL_EXTENSION
      );
    });
  }
  static convertDateString(date, patternModel) {
    // patternModal ex: 'dd-MMM-yyyy', 'yyyy-MM-dd'
    const locale = "en-us";
    //  const pattern = 'dd-MMM-yyyy';
    const pattern = patternModel;
    const datePipe = new DatePipe(locale);
    const stringDateFormat = datePipe.transform(date, pattern);
    return stringDateFormat;
  }

  logMessage(msg) {
    console.log(msg);
  }
  static filterReqId(tempReq, term) {
    let regex = new RegExp(tempReq, "g");
    if (tempReq.toUpperCase().match(term.toUpperCase())) {
      term = term.replace(/\D/g, "");
    } else {
      term = term.toUpperCase().replace(regex, "");
    }
    return term;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';


import { ContextService } from '../shared/context.service';
import { ApiService } from '../shared/api.service';


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private api: ApiService) { }

  getUserDetailsById(id: number): Observable<any> {
    return this.api.get('/person/userdetails/' + id).pipe(
      map(res => res ? res.body : res)
    );
  }
  // userLogin(credentials): Observable<any> { // b4 tckn
  //   return this.api.post1('/user/auth', credentials).pipe(
  //     map(res => res ? res.body : res )
  //   );
  // }
  sendForgotPasswordMail(loginId): Observable<any> {
    return this.api.getT('/user/sendpasswordresetcode/' + loginId).pipe(
      map(res => res ? res.body : res)
    );
  }

  getNotifications(): Observable<any> {
    return this.api.get('/notification/authenticatedusernotifications').pipe(
      map(res => res ? res.body : res)
    );
  }
  userLogin(): Observable<any> {
    return this.api.getT('/user/authenticate/1').pipe(map(res => res ? res.body : res));
  }
  changeTempPassword(credentials): Observable<any> {
    return this.api.post('/user/changetemppassword', credentials).pipe(
      map(res => res ? res.body : res)
    );
  }
  resetPassword(credentials): Observable<any> {
    return this.api.post('/user/resetpassword', credentials).pipe(
      map(res => res ? res.body : res)
    );
  }

}

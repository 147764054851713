import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class HttpServiceInterceptor implements HttpInterceptor {
  constructor(public router: Router) { }
  // function which will be called for all http calls
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // how to update the request Parameters
    const updatedRequest = request.clone({
      headers: request.headers.set('Authorization', 'Some-dummyCode')
    });
    // logging the updated Parameters to browser's console
    console.log('Before making api call : ', updatedRequest);
    return next.handle(request).pipe(
      tap(

        event => {
          try {
            // logging the http response to browser's console in case of a success
            if (event instanceof HttpResponse) {

              console.log('api call success :', event);

              //  console.log('api token :', event.headers.get('access_token'));
              if (event.headers !== undefined) {
                if (event.headers.get('access_token') !== null) {

                  // localStorage.setItem('access-tocken', event.headers.get('access_token'));
                  sessionStorage.setItem('access-tocken', event.headers.get('access_token'));

                } else {
                  console.log('token not found');
                }
              }
            }
          } catch (e) {
            console.log('Error:', e.message);
            this.logout();
          }
        },
        error => {
          try {
            // logging the http response to browser's console in case of a failuer
            if (event instanceof HttpResponse) {
              console.log('api call error :', event);

              if (event.headers !== undefined) {
                if (event.headers.get('access_token') !== null) {

                  // localStorage.setItem('access-tocken', event.headers.get('access_token'));
                  sessionStorage.setItem('access-tocken', event.headers.get('access_token'));

                } else {
                  console.log('token not found');
                }
              }
            }
          } catch (e) {
            console.log('Error:', e.message);
            this.logout();
          }
        }
      )
    );
  }
  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
    // this.router.navigate(['/login'], { queryParams: { data: 'logout' } });
  }
}

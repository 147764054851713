import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
@Pipe({name: 'dateWithFormatPipe'})
export class DateWithFormatPipe implements PipeTransform {
    datepipeFormat = 'dd-MMM-yyyy hh:mm a';
    locale = 'en-us';
  constructor() { }
  transform(value: Date): string {
    const locale = this.locale;
    const pattern = this.datepipeFormat;
    const datePipe = new DatePipe(locale);
    const result = datePipe.transform(value, pattern);
    return result;
  }
}

export class ItemPendingStatus {
    taskId: string;
    taskType: string;
    content: string;
    updatingMode:string;
    comment: string;
    scheduledDate: string;
    paymentTermId:number
    withdrawalRequestId:any;
}

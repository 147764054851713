import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate } from '@angular/router';

// import { ProductRegistrationComponent } from '../admin/pages/product-registration/product-registration.component'
import { ContextService } from '../shared/context.service';
import { Observable } from 'rxjs';

// export interface CanComponentDeactivate {
//     canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
// }

@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(private router: Router, private contextService: ContextService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (localStorage.getItem('currentUser') &&
            localStorage.getItem('userRoleList') &&
            localStorage.getItem('userPageFunctionalities') &&
            localStorage.getItem('nodeId') &&
            localStorage.getItem('userId') &&
            sessionStorage.getItem('menuList')) {
            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
